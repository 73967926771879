import { useAtom, useAtomValue } from 'jotai'
import Dialog from '@/components/dialog'
import { auth0SignInAtom, followUsDialogOpenAtom, readedNewFeatureKeysAtom } from '@/atoms'
import IconClose from '@haiper/icons-svg/icons/outline/crossed-large.svg'
import { cls, setLocalStorage } from '@/utils'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import Button from '@/components/button'
import { CACHE_KEY_NEW_FEATURE_DIALOG } from '@/constants'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import useAmplitude from '@/hooks/useAmplitude'
import Image from '../image'
import { useCachedNewFeatureAnnouncement } from '@/hooks/useNewFeatureAnnouncement'
import { uniq } from 'lodash-es'
import { usePathname, useRouter } from 'next/navigation'
import useSurvey from '@/hooks/useSurvery'

let disableNewFeatureDialog = false

export default function NewFeatureDialog() {
  const [readedNewFeatureKeys, setReadedNewFeatureKeys] = useAtom(readedNewFeatureKeysAtom)
  const isLogin = useAtomValue(auth0SignInAtom)
  const { track } = useAmplitude()
  const { data: newFeatureAnnouncement, isValidating: newFeatureAnnouncementLoading } =
    useCachedNewFeatureAnnouncement()
  const router = useRouter()
  const content: string[] = useMemo(() => {
    const value: string | string[] = newFeatureAnnouncement?.content ?? []
    return Array.isArray(value) ? value : (value as string).split('\n')
  }, [newFeatureAnnouncement])

  const read = useCallback(() => {
    if (!newFeatureAnnouncement?.key) {
      return
    }
    const newKeys = uniq([...readedNewFeatureKeys, newFeatureAnnouncement.key])
    setReadedNewFeatureKeys(newKeys)
    setLocalStorage(CACHE_KEY_NEW_FEATURE_DIALOG, newKeys)
  }, [readedNewFeatureKeys, newFeatureAnnouncement, setReadedNewFeatureKeys])

  const handleClose = useCallback(() => {
    read()
  }, [read])

  const handleOk = useCallback(() => {
    if (!newFeatureAnnouncement?.key && newFeatureAnnouncement?.ok_event) {
      track(newFeatureAnnouncement.ok_event)
    }
    handleClose()
    if (newFeatureAnnouncement?.next_url) {
      router.push(newFeatureAnnouncement.next_url)
    }
  }, [track, handleClose, newFeatureAnnouncement, router])

  const viewEventTrackedRef = useRef(false)

  const { isBelowMd } = useBreakpoint('md')

  const previewUrl = useMemo(() => {
    return isBelowMd ? newFeatureAnnouncement?.mobile_image : newFeatureAnnouncement?.pc_image
  }, [isBelowMd, newFeatureAnnouncement])

  const pathname = usePathname()

  const realOpen =
    !!newFeatureAnnouncement &&
    !newFeatureAnnouncement?.disabled &&
    !readedNewFeatureKeys.includes(newFeatureAnnouncement.key) &&
    !newFeatureAnnouncementLoading &&
    isLogin &&
    !pathname.startsWith('/auth') &&
    !pathname.startsWith('/onboarding')

  useEffect(() => {
    if (realOpen && !viewEventTrackedRef.current && newFeatureAnnouncement?.view_event) {
      track(newFeatureAnnouncement?.view_event)
      viewEventTrackedRef.current = true
    }
  }, [realOpen, track, newFeatureAnnouncement])
  const followUsDialogOpen = useAtomValue(followUsDialogOpenAtom)

  const { showSurvey } = useSurvey()
  disableNewFeatureDialog = disableNewFeatureDialog || showSurvey || followUsDialogOpen

  // disable new feature dialog unless refresh the page
  if (disableNewFeatureDialog) {
    return null
  }

  return (
    <Dialog
      open={realOpen}
      title={
        <div className='flex flex-col gap-6'>
          <Image
            className='cursor-pointer rounded-md border border-border h-[175px] md:h-[298px]'
            src={previewUrl}
            alt='new-feature-preview'
            onClick={handleOk}
          />
          <div className='flex items-start justify-between gap-4'>
            <div className='flex items-center gap-1 text-text font-bold text-heading-2xl leading-[normal] min-h-10'>
              {newFeatureAnnouncement?.title}
            </div>
            <Button
              variant='transparent'
              className={cls(
                'p-0 size-10 rounded-lg focus-visible:shadow-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted border-0 shadow-none',
              )}
              aria-label='dialog close button'
              onClick={handleClose}
            >
              <IconClose className='size-6 text-icon' />
              <span className='sr-only'>Close</span>
            </Button>
          </div>
        </div>
      }
      titleClassName='mb-3'
      className='gap-0 w-[343px] md:w-[578px] p-4 md:p-6'
      closeClassName='hidden'
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={handleClose}
    >
      <div className='w-full mt-3 text-text flex flex-col gap-6 md:gap-8 text-body-md tracking-15'>
        {content.length > 1 ? (
          <ul className='list-disc list-inside px-1'>
            {content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          <span className=''>{content[0] ?? ''}</span>
        )}
        <Button
          variant='primary'
          className='w-full text-body-md font-normal tracking-15 align-middle'
          onClick={handleOk}
        >
          {newFeatureAnnouncement?.ok_text || 'Learn more'}
        </Button>
      </div>
    </Dialog>
  )
}
